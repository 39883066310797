import React from 'react';
import vernonHome from './vernon-home.jpg'; // Tell webpack this JS file uses this image
import './styles/banner.css';



function Banner() {

    return (
        <div>
            <div className="banner">
                <img className="banner__image" src={vernonHome} alt="Logo" />
            </div>
        </div>
    );
}


export default Banner;