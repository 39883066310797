import React from 'react';
import './App.css';
import Photos from './content/photos/Photos';
import Events from './content/events/Events';
import BoardMemebers from './content/boardMembers/BoardMemebers';
import Home from './content/home/Home';
import Footer from './footer/Footer';
import About from './content/about/About';
import Mission from './content/mission/Mission';
import Donate from './content/donate/Donate';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import ContactUs from './content/contactUs/ContactUs';
import Volunteer from './content/volunteer/Volunteer';
class RouterContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalImageUrl: "",
            navExpanded:false
        }
    }
    Navigate = (to) => {
        this.setState({ navExpanded: false });
        this.props.history.push(to);
    }

    toggleNav = (toggleValue) => {
        this.setState({ navExpanded: toggleValue });
    }
    render() {
        return (
            <div className="router">
                <div className="router-wrapper">
                    <div className="router-wrapper__inside">
                        <Navbar onToggle={this.toggleNav} expanded={this.state.navExpanded} fixed="top" className="banner__menu" collapseOnSelect expand="lg" bg="dark" variant="dark">
                            <Navbar.Brand href="#" onClick={() => this.Navigate("/")}>Vernon Multipurpose Center</Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">

                                </Nav>
                                <Nav>
                                    <Nav.Link onClick={() => this.Navigate("/")}>Home</Nav.Link>
                                    <Nav.Link onClick={() => this.Navigate("/photos")}>Photos</Nav.Link>
                                    <Nav.Link onClick={() => this.Navigate("/boardMembers")}>Board Members</Nav.Link>
                                    <Nav.Link onClick={() => this.Navigate("/about")}>About</Nav.Link>
                                    <Nav.Link onClick={() => this.Navigate("/mission")}>Mission</Nav.Link>
                                    <Nav.Link onClick={() => this.Navigate("/events")}>Events</Nav.Link>
                                    <Nav.Link onClick={() => this.Navigate("/contactUs")}>Contact Us</Nav.Link>
                                    <Nav.Link onClick={() => this.Navigate("/volunteer")}>Volunteer</Nav.Link>
                                    <Button onClick={() => this.Navigate("/donate")}>Donate</Button>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                        <Switch>
                            <Route path="/events">
                                <Events/>
                            </Route>
                            <Route path="/photos">
                                <Photos/>
                            </Route>
                            <Route path="/boardMembers">
                                <BoardMemebers />
                            </Route>
                            <Route path="/about">
                                <About />
                            </Route>
                            <Route path="/mission">
                                <Mission />
                            </Route>
                            <Route path="/donate">
                                <Donate />
                            </Route>
                            <Route path="/volunteer">
                                <Volunteer />
                            </Route>
                            <Route path="/contactUs">
                                <ContactUs />
                            </Route>
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch>
                    </div>
                </div>
                        <Footer/>
                    </div>

        );
    }
}

export default withRouter(RouterContent);