import React from 'react';
import "./styles/donate.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function Donate() {
    return (
        <ContentWrapper header="Donate">
            <div className="donate">
                <p>
                    For donation infomation, email us here: <a href="mailto:info@vernoncenterkck.org?subject=Donation Question">here</a>.
                </p>
            </div>
        </ContentWrapper>
    );
}

export default Donate;