import React from 'react';
import Modal from 'react-bootstrap/Modal'
import "./styles/imageModal.css"
function ImageModal({ show, closeModal,url }) {

    return (
        <>
            <Modal
                show={show}
                onHide={closeModal}
                dialogClassName="image-modal"
                aria-labelledby="Image-Modal"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img className="banner__image" src={url} alt="" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ImageModal;