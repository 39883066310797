import React from 'react';
import Card from 'react-bootstrap/Card';
import "./styles/boardMembers.css";
import { withRouter } from "react-router-dom";
import ContentWrapper from '../contentWrapper/ContentWrapper'

const members = [
    {
        name: "Helen Beteet",
        title: "President",
        photo:"helen_beteet.JPG"
    },
    {
        name: "Thomas Gordan",
        title: "Vice President"
    },
    {
        name: "Cedric Patton",
        title:"Corresponding Secretary"
    },
    
    {
        name: "Hazel Davis",
        title: "Treasurer "
    },
    {
        name: "Beverly Adams",
        title: "Board Member"
    },
    {
        name: "Larry C. Caldwell Sr.",
        title: "Sergeant of Arms"
    },
    {
        name: "Willie Etta Jennings",
        title: "Board Member"
    },
    {
        name: "Joyce White",
        title: "Board Member"
    },
    {
        name: "Luther Smith",
        title: "Museum Curator"
    },
    {
        name: "Clarence Brown",
        title: "Board Member"
    },
    {
        name: "Brenda Price",
        title: "Board Member"
    },
    {
        name: "Tanesha Thompson",
        title: "Recording Secretary"
    },
];

class BoardMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    Navigate = (to) => {
        this.props.history.push(to);
    }
    getCard = (member,index) => {
        const imagesBase = process.env.PUBLIC_URL + '/images/';
        const noPhotosImage = `${imagesBase}/${member.photo || "NoPersonPhoto.png"}`;
        return (
            <li className="board-members__member" key={ index}>
                <Card style={{ width: '12rem' }}>
                    <Card.Img variant="top" src={noPhotosImage} />
                    <Card.Body>
                        <Card.Title>{member.name}</Card.Title>
                        <Card.Text>{member.title}</Card.Text>
                    </Card.Body>
                </Card>
            </li>
        );
    }
    render() {
        return (
            <ContentWrapper header="Board Members">
                <ul className="board-members">
                    {members.map((member,i) => this.getCard(member,i))}
                </ul>
            </ContentWrapper>
        );
    }
}

export default withRouter(BoardMembers);