import React from 'react';
import "./styles/contentWrapper.css";

function ContentWrapper({ children,header}) {
    return (
        <div className="content-wrapper">
            <h1>{header}</h1>
            {children}
        </div>
    );
}

export default ContentWrapper;