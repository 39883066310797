import React from 'react';
import './App.css';
import RouterContent from './RouterContent'
import ScrollToTop from './ScrollToTop'
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
const title = "Vernon Multipurpose Center";
const description = "Our Mission is to function as an outreach facility, serving citizens in the northeast area of Wyandotte County, Kansas City Kansas.The Center attempts to enhance the general physical well being of the community it serves.";
class App extends React.Component {
    metaData = [
        {
            name: `description`,
            content: description,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: description,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: description,
        },
        {
            name: `twitter:creator`,
            content: "Defined Innovations",
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: description,
        }
    ]
    render() {
        return (
            <div className="App">
                <Helmet
                    title={title}
                    meta={this.metaData}
                   
                />
                <Router>
                    <ScrollToTop/>
                    <RouterContent/>
                </Router>
            </div>
        );
    }
}

export default App;
