import React from 'react';

import "./styles/footer.css";


function Footer() {
    return (
        <div className="footer">
            Vernon Multi Purpose Center Website
        </div>
    );
}

export default Footer;