import React from 'react';
import "./styles/volunteer.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function Volunteer() {
    return (
        <ContentWrapper header="Volunteer">
            <div className="volunteer">
                <p>
                    To volunteer, please fill out this <a href="./Volunteer_Application_2023_VMPC.pdf">form</a> and return to:  info@vernoncenterkck.org or submit at the VMPC Monday thru Friday between 10am and noon or mail directly to the center..
                </p>
            </div>
        </ContentWrapper>
    );
}

export default Volunteer;