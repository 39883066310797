import React from 'react';
import "./styles/about.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function About() {
    return (
        <ContentWrapper header="About">
            <div className="about">
                <h3>Vernon School & Quindaro Railroad Museum</h3>
                <p>
                    The Vernon School was known as, The Colored School of Quindaro. It
                    opened in 1858. The original school's purpose was built to educate African
                    American children in the abolitionist community of Quindaro. The School
                    began operation in the years leading up to the civil War when it provided
                    instruction to African Americans including many former slaves who had just
                    arrived in the territory of Kansas.The school was segregated and run by its
                    own district (#17) with a board of all African Americans. By the 1930s, the
                    school was in despair and overcrowded. In1936, the old building was
                    razed and a new brick school was built in its place. The new school had 4
                    rooms and served the 1st through 8th grade. In 1950 two rooms were
                    added.This facility was built using President Roosevelt's New Deal Program.
                    The Colored School of Quindaro was renamed Vernon School after William
                    Tecumseh Vernon.At that time Vernon was only 25 yrs old. He was an
                    accomplished speaker and writer.  He was an African American Leader from
                    Kansas City who had served as the president at the Western University
                    and also credited with expanding the university's curriculum and state
                    funding. In 1906 President Theodore Roosevelt appointed Vernon
                    Register of the US Treasury, the highest post ever achieved by an African
                    American, at that time. Vernon later became consecrated as a bishop with
                    the African Methodist Epicapol (AME) Church and worked as a missionary
                    in South Africa.
                </p>
                <p>
                        In 1954, the Brown vs the Board of Education of Topeka landmark case
                        desegregated schools across the country. The next year, about one third of
                        the Vernon School's children were sent to integrate the Quindaro
                        Elementary School. Due to desegregation and partially due to a decreasing
                        population of Quindaro, the Vernon School closed in 1971. The building
                        was then and now is being used as a community center. In 2004 the
                        building was added to the National Historic Register and is now called the
                        Vernon Multi Purpose Center. Its mission is to function as an outreach
                        facility, serving Wyandotte County citizens. The Center's purpose and goal
                        is to enhance the general , physical, and well being of the community it
                        serves. The center also houses the Underground Railroad Museum. It
                        supports and assist the historical preservations of the documents, secured
                        by the museum.
                </p>
            </div>
        </ContentWrapper>
    );
}

export default About;