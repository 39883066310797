import React from 'react';

//import Image from 'react-bootstrap/Image';
import "./styles/mission.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function Mission() {
    return (
        <ContentWrapper header="Mission">
            <div className="mission">
                <h3>Our Mission</h3>
                <p>
                    Our Mission is to function as an outreach facility, serving citizens in the
                    northeast area of Wyandotte County, Kansas City Kansas. The Center
                    attempts to enhance the general physical well being of the community it
                    serves.
                </p>
            </div>
        </ContentWrapper>
    );
}

export default Mission;