import React from 'react';

import Image from 'react-bootstrap/Image';
import ImageModal from '../../imageModal/ImageModal'
import ContentWrapper from '../contentWrapper/ContentWrapper';
import "./styles/photos.css";



class Photos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalImageUrl:""
        }
    }
    GetPhotoLinks=()=> {
        const links = [];
        const base = process.env.PUBLIC_URL + '/images/walk-through';
        for (var i = 0; i < 24; i++) {
            links.push({
                src: `${base}${i}.jpg`,
                thumbnailSrc: `${base}${i}-thumbnail.jpg`
            });
        }
        return links;
    }
    openImageModal = (modalImageUrl) => {
        this.setState({ modalImageUrl, showModal:true })
    }
    closeModal = () => {
        this.setState({ showModal: false });
    }
    GetPhoto = (photoInfo, index)=> {
        return (
            <li className="photos__item" onClick={() => this.openImageModal(photoInfo.src)} key={index}>
                <Image src={photoInfo.thumbnailSrc} thumbnail />
            </li>
        );
    }
    render() {
        return (
            <ContentWrapper header="Photos">
                <ul className="photos">
                    {this.GetPhotoLinks().map((link,i) => this.GetPhoto(link,i))}
                </ul>
                <ImageModal show={this.state.showModal} url={this.state.modalImageUrl} closeModal={this.closeModal} />
            </ContentWrapper>
        );
    }
    
}

export default Photos;