import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import "./styles/showcaseLinks.css";
import { withRouter } from "react-router-dom";

const imagesBase = process.env.PUBLIC_URL + '/images/';
const showcases = [
    {
        photoSrc:`${imagesBase}walk-through0-thumbnail.jpg`,
        linkText:"About",
        link:"/about"
    },
    {
        photoSrc: `${imagesBase}vernon-event.jpg`,
        linkText: "Events",
        link: "/events"
    },
    {
        photoSrc: `${imagesBase}walk-through17-thumbnail.jpg`,
        linkText: "Photos",
        link: "/photos"
    }
];

class ShowcaseLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalImageUrl: ""
        }
    }
    Navigate = (to) => {
        this.props.history.push(to);
    }
    getCard=(showCase,index)=> {
        return (
            <li className="showcase-links__link" key={index}>
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={showCase.photoSrc} />
                    <Card.Body>
                        <Card.Title>{showCase.linkText}</Card.Title>
                        <Card.Text>
                        </Card.Text>
                        <Button variant="primary" onClick={() => this.Navigate(showCase.link)} > View</Button>
                    </Card.Body>
                </Card>
            </li>
        );
    }
    render() {
        return (
            <ul className="showcase-links">
                {showcases.map((showCase,i) => this.getCard(showCase,i))}
            </ul>
        );
    }
}

export default withRouter(ShowcaseLinks);